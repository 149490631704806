import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress, Grid, TextField, IconButton, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import useNotify from '../../hooks/useNotify';
import { doSwaggerCall } from '../../hooks/useApi';
import PageWithTitle from '../../components/atom/PageWithTitle';
import CommonButton from '../../components/atom/CommonButton';
import { handleNavigateBack } from '../../helpers/handleDetailsPageFunctions';

const NAVIGATE_BACK_URL = '/payments';

const defaultValue = {
    refreshStatus: '',
    user_id: '',
    status: '',
    entryTime: '-',
    exitTime: '-',
    amount: '',
    freeTimeAfterEntry: '',
    freeTimeAfterPayment: '',
    paid: '',
};

function PaymentDetailsPage() {
    const { paymentId } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [payment, setPayment] = useState(null);
    const { notifyError } = useNotify();
    const location = useLocation();
    const hasHistory = location.state?.history && location.state?.history.length > 0;

    const loadPayment = useCallback(() => {
        setLoading(true);
        doSwaggerCall('Payments', 'getPayment', { id: paymentId })
            .then((res) => {
                setPayment({
                    refreshStatus: res.refreshStatus,
                    orderref: res.payment.orderref,
                    amount: res.payment.amount,
                    email: res.payment.email,
                    user_id: res.payment.user_id,
                    ticket_id: res.payment.ticket_id,
                    status: res.payment.status,
                });
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notifyError(err);
                navigate(NAVIGATE_BACK_URL);
            });
    }, [paymentId]);

    useEffect(() => {
        loadPayment();
    }, [loadPayment]);

    if (loading) {
        return (
            <Grid item xs={12}>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Formik initialValues={payment || { ...defaultValue }}>
            {({ values }) => (
                <Form>
                    <PageWithTitle title="Fizetés">
                        <Grid item xs={12} sx={{ pb: 4 }}>
                            <Grid container spacing={0} alignItems="center">
                                <Grid item xs={0.5}>
                                    {values.refreshStatus === '1' ? (
                                        <IconButton>
                                            <CheckIcon color="success" />
                                        </IconButton>
                                    ) : (
                                        <IconButton>
                                            <CloseIcon color="error" />
                                        </IconButton>
                                    )}
                                </Grid>
                                <Grid item xs={11.5}>
                                    <Typography>Frissítési státusz</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Befizetés ID"
                                value={values.orderref}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Összeg (Ft)"
                                value={values.amount}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="E-mail"
                                value={values.email}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Felhasználó ID"
                                value={values.user_id}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Parkolójegy ID"
                                value={values.ticket_id}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Státusz"
                                value={values.status}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {hasHistory && (
                                <CommonButton
                                    onClick={() => {
                                        handleNavigateBack(navigate, location, '/payments');
                                    }}
                                    label="Vissza"
                                    buttonType="text"
                                />
                            )}
                        </Grid>
                    </PageWithTitle>
                </Form>
            )}
        </Formik>
    );
}

export default PaymentDetailsPage;
