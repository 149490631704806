import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Grid, IconButton, Select, MenuItem, TableCell, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import PageWithTitle from '../../components/atom/PageWithTitle';
import { TABLE_ROW_COUNT_DEFAULT, PAID_STATUSES } from '../../helpers/constants';
import { PARKINGS_TABLE_COLUMNS } from '../../helpers/tableColumnLists';
import GenericTable from '../../components/atom/GenericTable';
import SearchInput from '../../components/atom/SearchInput';
import handleNavigation from '../../helpers/handleNavigation';

const ParkingsPage = () => {
    const { notifyError } = useNotify();
    const location = useLocation();
    const navigate = useNavigate();

    const [rows, setRows] = useState([]);
    const [searchStr, setSearchStr] = useState('');
    const [searchUserStr, setSearchUserStr] = useState('');
    const [searchIsPaid, setSearchIsPaid] = useState('');
    const [searchStatusStr, setSearchStatusStr] = useState('');
    const [paidStatus, setPaidStatus] = useState(PAID_STATUSES.ALL);
    const [{ limit, page, sortorder, sortby }, setParams] = useState({
        limit: TABLE_ROW_COUNT_DEFAULT,
        page: 0,
        sortorder: 'DESC',
        sortby: 'entryTime',
    });
    const [data, loading, error] = useApi('ParkingTickets', 'getParkingTickets', {
        limit,
        offset: page * limit,
        search: searchStr,
        searchUserId: searchUserStr,
        searchPayed: searchIsPaid,
        searchStatus: searchStatusStr,
        sortorder,
        sortby,
    });

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    useEffect(() => {
        setParams({
            limit: TABLE_ROW_COUNT_DEFAULT,
            page: 0,
            search: searchStr,
            searchUserId: searchUserStr,
            searchPayed: searchIsPaid,
            searchStatus: searchStatusStr,
            sortorder,
            sortby,
        });
    }, [searchStr, searchUserStr, searchIsPaid, searchStatusStr, sortorder, sortby]);

    useEffect(() => {
        switch (paidStatus) {
            case PAID_STATUSES.ALL:
                setSearchIsPaid('');
                break;
            case PAID_STATUSES.PAID:
                setSearchIsPaid(1);
                break;
            case PAID_STATUSES.NOT_PAID:
                setSearchIsPaid(0);
                break;
            default:
                setSearchIsPaid('');
        }
    }, [paidStatus]);

    useEffect(() => {
        setRows(
            data?.tickets.map((ticket) => {
                return {
                    amount: ticket.amount,
                    entryTime: ticket.entryTime ? new Date(ticket.entryTime).toLocaleString() : '-',
                    exitTime: ticket.exitTime ? new Date(ticket.exitTime).toLocaleString() : '-',
                    freeTimeAfterEntry: ticket.freeTimeAfterEntry,
                    freeTimeAfterPayment: ticket.freeTimeAfterPayment,
                    id: ticket.id,
                    paid: ticket.paid ? (
                        <IconButton>
                            <CheckIcon color="success" />
                        </IconButton>
                    ) : (
                        <IconButton>
                            <CloseIcon color="error" />
                        </IconButton>
                    ),
                    status: ticket.status,
                    user_id: ticket.user_id || '-',
                    detailsIcon: (
                        <TableCell align="right" sx={{ width: '5%' }}>
                            <Tooltip title="Részletek">
                                <IconButton
                                    onClick={() => {
                                        handleNavigation(location, (stateObj) => {
                                            navigate(`/parkings/details/${ticket.id}`, stateObj);
                                        });
                                    }}
                                >
                                    <VisibilityIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    ),
                };
            })
        );
    }, [data?.tickets]);

    return (
        <PageWithTitle title="Parkolások">
            <Formik
                initialValues={{
                    searchTicketId: '',
                    searchUserId: '',
                    searchStatus: '',
                    typeValue: '',
                    paidStatus,
                }}
            >
                {({ setFieldValue }) => (
                    <Form>
                        <Grid
                            container
                            fullWidth
                            sx={{ minWidth: '500px', pl: 3, pt: 2 }}
                            spacing={2}
                        >
                            <Grid container item xs={3} alignItems="end">
                                <Select
                                    value={paidStatus}
                                    label="paidStatus"
                                    onChange={(e) => {
                                        setPaidStatus(e.target.value);
                                    }}
                                    fullWidth
                                >
                                    {Object.keys(PAID_STATUSES).map((status) => (
                                        <MenuItem value={PAID_STATUSES[status]}>
                                            {PAID_STATUSES[status]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={3}>
                                <SearchInput
                                    sm={12}
                                    name="searchTicketId"
                                    label="Parkolójegy ID"
                                    type="text"
                                    onChangeProps={{
                                        fieldId: 'searchTicketId',
                                        setFieldValue,
                                        setSearchStr,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <SearchInput
                                    sm={12}
                                    name="searchUserId"
                                    label="Felhasználó ID"
                                    type="text"
                                    onChangeProps={{
                                        fieldId: 'searchUserId',
                                        setFieldValue,
                                        setSearchStr: setSearchUserStr,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <SearchInput
                                    sm={12}
                                    name="searchStatus"
                                    label="Státusz"
                                    type="text"
                                    onChangeProps={{
                                        fieldId: 'searchStatus',
                                        setFieldValue,
                                        setSearchStr: setSearchStatusStr,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
            <GenericTable
                rows={rows}
                limit={limit}
                page={page}
                setParams={setParams}
                loading={loading}
                total={data?.total}
                order={sortorder}
                orderBy={sortby}
                columns={PARKINGS_TABLE_COLUMNS}
                navigationLink="/parkings/details/"
                detailsIcon={<VisibilityIcon />}
            />
        </PageWithTitle>
    );
};

export default ParkingsPage;
