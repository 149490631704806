import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Grid, Tooltip, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useLocation, useNavigate } from 'react-router-dom';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PersonIcon from '@mui/icons-material/Person';
import useNotify from '../../hooks/useNotify';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constants';
import { useApi } from '../../hooks/useApi';
import PageWithTitle from '../../components/atom/PageWithTitle';
import GenericTable from '../../components/atom/GenericTable';
import { PAYMENTS_TABLE_COLUMNS } from '../../helpers/tableColumnLists';
import SearchInput from '../../components/atom/SearchInput';
import handleNavigation from '../../helpers/handleNavigation';

const PaymentsPage = () => {
    const { notifyError } = useNotify();
    const location = useLocation();
    const navigate = useNavigate();

    const [rows, setRows] = useState([]);
    const [searchStr, setSearchStr] = useState('');
    const [searchUserStr, setSearchUserStr] = useState('');
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const [data, loading, error] = useApi('Payments', 'getPayments', {
        limit,
        offset: page * limit,
        search: searchStr,
        searchUserId: searchUserStr,
    });

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    useEffect(() => {
        setParams({
            limit: TABLE_ROW_COUNT_DEFAULT,
            page: 0,
            search: searchStr,
            searchUserId: searchUserStr,
        });
    }, [searchStr, searchUserStr]);

    useEffect(() => {
        setRows(
            data?.payments.map((payment) => {
                return {
                    amount: payment.amount,
                    email: payment.email,
                    orderref: payment.orderref,
                    status: payment.status,
                    ticketId: payment.ticket_id,
                    userId: payment.user_id,
                    parkingDetails: (
                        <Tooltip title="Parkolójegy részletei">
                            <IconButton
                                onClick={() => {
                                    handleNavigation(location, (stateObj) => {
                                        navigate(
                                            `/parkings/details/${payment.ticket_id}`,
                                            stateObj
                                        );
                                    });
                                }}
                            >
                                <DirectionsCarIcon />
                            </IconButton>
                        </Tooltip>
                    ),
                    userDetails: (
                        <Tooltip title="Felhasználó adatai">
                            <IconButton
                                onClick={() => {
                                    handleNavigation(location, (stateObj) => {
                                        navigate(`/users/edit/${payment.user_id}`, stateObj);
                                    });
                                }}
                            >
                                <PersonIcon />
                            </IconButton>
                        </Tooltip>
                    ),
                };
            })
        );
    }, [data?.payments]);

    return (
        <PageWithTitle title="Fizetések">
            <Formik
                initialValues={{
                    searchPaymentId: '',
                    searchUserId: '',
                    searchTicketId: '',
                    typeValue: '',
                }}
            >
                {({ setFieldValue }) => (
                    <Form>
                        <Grid
                            container
                            fullWidth
                            sx={{ minWidth: '500px', pl: 2, pt: 2 }}
                            spacing={2}
                        >
                            <Grid item xs={4}>
                                <SearchInput
                                    sm={12}
                                    name="searchPaymentId"
                                    label="Befizetés ID"
                                    type="text"
                                    onChangeProps={{
                                        fieldId: 'searchPaymentId',
                                        setFieldValue,
                                        setSearchStr,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <SearchInput
                                    sm={12}
                                    name="searchUserId"
                                    label="Felhasználó ID"
                                    type="text"
                                    onChangeProps={{
                                        fieldId: 'searchUserId',
                                        setFieldValue,
                                        setSearchStr: setSearchUserStr,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
            <GenericTable
                rows={rows}
                limit={limit}
                page={page}
                setParams={setParams}
                loading={loading}
                total={data?.total}
                columns={PAYMENTS_TABLE_COLUMNS}
                navigationLink="/payments/details/"
                detailsIcon={<VisibilityIcon />}
            />
        </PageWithTitle>
    );
};

export default PaymentsPage;
