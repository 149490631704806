import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Button, Grid, Tooltip, IconButton, TableCell } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { utils, writeFile } from 'xlsx';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApi, doSwaggerCall } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constants';
import PageWithTitle from '../../components/atom/PageWithTitle';
import GenericTable from '../../components/atom/GenericTable';
import { USERS_TABLE_COLUMNS } from '../../helpers/tableColumnLists';
import SearchInput from '../../components/atom/SearchInput';
import handleNavigation from '../../helpers/handleNavigation';

const USER_EXPORT_PER_PAGE_LIMIT = 100;

const UsersPage = () => {
    const { notifyError } = useNotify();
    const location = useLocation();
    const navigate = useNavigate();

    const [rows, setRows] = useState([]);
    const [searchStr, setSearchStr] = useState('');
    const [{ limit, page, sortorder, sortby }, setParams] = useState({
        limit: TABLE_ROW_COUNT_DEFAULT,
        page: 0,
        sortby: 'lastname',
        sortorder: 'ASC',
    });
    const [data, loading, error] = useApi('Users', 'getUsers', {
        limit,
        offset: page * limit,
        search: searchStr,
        sortby,
        sortorder,
    });

    const collectAllData = async () => {
        const allData = [];
        const localPageSize = USER_EXPORT_PER_PAGE_LIMIT;

        let i = 0;
        while (true) {
            // eslint-disable-next-line no-await-in-loop
            const res = await doSwaggerCall('Users', 'getUsers', {
                limit: localPageSize,
                offset: i * localPageSize,
            });
            res.users.forEach((e) => allData.push(e));
            i++;
            if (res.users.length < localPageSize) {
                break;
            }
        }

        return allData.map((user) => ({
            Azonosító: user.id,
            Vezetéknév: user.lastname,
            Keresztnév: user.firstname,
            'E-mail cím': user.email,
            Regisztráció: user.created_at.replaceAll('-', '. '),
            'Születési dátum': user.birthdate?.replaceAll('-', '. ') || '-',
            Nyelv: user.lang,
            Jogosultság: user.role,
            'Dolgozói QR': user.vipurl || '-',
            'Lurdy Life tag': user.lurdylifemember ? 'igen' : 'nem',
        }));
    };

    const handleExport = async () => {
        const allData = await collectAllData(limit, data);
        const worksheet = utils.json_to_sheet(allData);
        const workbook = utils.book_new();

        utils.book_append_sheet(workbook, worksheet, 'Felhasznalok');
        writeFile(workbook, 'felhasznalok.xls', { compression: true });
    };

    const exportButton = () => {
        return (
            <Button sx={{ height: 35, pt: 0, pb: 0 }} onClick={() => handleExport()}>
                Felhasználók exportálása
            </Button>
        );
    };

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    useEffect(() => {
        setParams({
            limit: TABLE_ROW_COUNT_DEFAULT,
            page: 0,
            search: searchStr,
        });
    }, [searchStr]);

    useEffect(() => {
        setRows(
            data?.users.map((user) => {
                return {
                    birthdate: user.birthdate?.replaceAll('-', '. ') || '-',
                    email: user.email,
                    firstname: user.firstname,
                    lang: user.lang,
                    lastname: user.lastname,
                    role: user.role,
                    id: user.id,
                    registration: user.created_at.replaceAll('-', '. '),
                    vipurl: user.vipurl || '-',
                    lurdylifemember: user.lurdylifemember ? (
                        <IconButton>
                            <CheckIcon color="success" />
                        </IconButton>
                    ) : (
                        <IconButton>
                            <CloseIcon color="error" />
                        </IconButton>
                    ),
                    editIcon: (
                        <TableCell align="right" sx={{ width: '5%' }}>
                            <Tooltip title="Részletek">
                                <IconButton
                                    onClick={() => {
                                        handleNavigation(location, (stateObj) => {
                                            navigate(`/users/edit/${user.id}`, stateObj);
                                        });
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    ),
                };
            })
        );
    }, [data?.users]);

    return (
        <PageWithTitle title="Felhasználók" headline={exportButton()}>
            <Grid container>
                <Grid item xs={10}>
                    <Formik initialValues={{ search: '' }}>
                        {({ setFieldValue }) => (
                            <Form>
                                <Grid container alignItems="center" sx={{ ml: 1.3 }}>
                                    <Tooltip
                                        title="Keresés vezetéknév, keresztnév, vagy e-mail alapján"
                                        arrow
                                    >
                                        <Grid item>
                                            <SearchInput
                                                sm={12}
                                                name="search"
                                                label="Keresés"
                                                type="text"
                                                onChangeProps={{
                                                    fieldId: 'search',
                                                    setFieldValue,
                                                    setSearchStr,
                                                }}
                                            />
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
            <GenericTable
                rows={rows}
                limit={limit}
                page={page}
                setParams={setParams}
                total={data?.total}
                loading={loading}
                order={sortorder}
                orderBy={sortby}
                columns={USERS_TABLE_COLUMNS}
                navigationLink="/users/edit/"
                detailsIcon={<EditIcon />}
            />
        </PageWithTitle>
    );
};

export default UsersPage;
