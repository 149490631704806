import React, { useCallback, useEffect, useState } from 'react';
import {
    CircularProgress,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Input,
    InputAdornment,
    IconButton,
    Tooltip,
    TableCell,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useNotify from '../../hooks/useNotify';
import { doSwaggerCall } from '../../hooks/useApi';
import PageWithTitle from '../../components/atom/PageWithTitle';
import CommonButton from '../../components/atom/CommonButton';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constants';
import GenericTable from '../../components/atom/GenericTable';
import { PAYMENTS_OF_TICKET_COLUMNS } from '../../helpers/tableColumnLists';
import { handleNavigateBack } from '../../helpers/handleDetailsPageFunctions';
import handleNavigation from '../../helpers/handleNavigation';

const NAVIGATE_BACK_URL = '/users';

const defaultParkingValue = {
    refreshStatus: '',
    user_id: '',
    status: '',
    entryTime: '-',
    exitTime: '-',
    amount: '',
    freeTimeAfterEntry: '',
    freeTimeAfterPayment: '',
    paid: '',
};

function ParkingDetailsPage() {
    const { parkingId } = useParams();
    const [parkingLoading, setParkingLoading] = useState(true);
    const [paymentsLoading, setPaymentsLoading] = useState(true);
    const navigate = useNavigate();
    const [parking, setParking] = useState(null);
    const [payments, setPayments] = useState([]);
    const { notifyError } = useNotify();
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const location = useLocation();
    const hasHistory = location.state?.history && location.state?.history.length > 0;
    const [paymentRows, setPaymentRows] = useState([]);
    const loadParking = useCallback(() => {
        setParkingLoading(true);
        doSwaggerCall('ParkingTickets', 'getParkingTicket', { id: parkingId })
            .then((res) => {
                setParking({
                    refreshStatus: res.refreshStatus,
                    id: res.ticket.id,
                    user_id: res.ticket.user_id,
                    status: res.ticket.status,
                    entryTime: res.ticket.entryTime,
                    exitTime: res.ticket.exitTime,
                    amount: res.ticket.amount,
                    freeTimeAfterEntry: res.ticket.freeTimeAfterEntry,
                    freeTimeAfterPayment: res.ticket.freeTimeAfterPayment,
                    paid: res.ticket.paid,
                });
                setParkingLoading(false);
            })
            .catch((err) => {
                setParkingLoading(false);
                notifyError(err);
                navigate(NAVIGATE_BACK_URL);
            });
    }, [parkingId]);

    const loadPayments = useCallback(() => {
        setPaymentsLoading(true);
        doSwaggerCall('Payments', 'getPayments', {
            offset: page * limit,
            limit,
            searchTicketId: parkingId,
        })
            .then((res) => {
                setPayments(res);
                setPaymentsLoading(false);
            })
            .catch((error) => {
                notifyError(error);
                setPaymentsLoading(false);
            });
    }, [page, limit, parkingId]);

    useEffect(() => {
        loadParking();
        loadPayments();
    }, [loadParking, loadPayments]);

    useEffect(() => {
        setPaymentRows(
            payments?.payments?.map((payment) => {
                return {
                    amount: payment.amount,
                    email: payment.email,
                    orderref: payment.orderref,
                    status: payment.status,
                    userId: payment.user_id,
                    detailsIcon: (
                        <TableCell align="right" sx={{ width: '5%' }}>
                            <Tooltip title="Részletek">
                                <IconButton
                                    onClick={() => {
                                        handleNavigation(location, (stateObj) => {
                                            navigate(
                                                `/payments/details/${payment.orderref}`,
                                                stateObj
                                            );
                                        });
                                    }}
                                >
                                    <VisibilityIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    ),
                };
            })
        );
    }, [payments?.payments]);

    if (parkingLoading || paymentsLoading) {
        return (
            <Grid item xs={12}>
                <CircularProgress />
            </Grid>
        );
    }
    return (
        <Formik initialValues={parking || { ...defaultParkingValue }}>
            {({ values }) => (
                <Form>
                    <PageWithTitle title={`Parkolás - ${parking.id}`}>
                        <Grid item xs={6}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="standard-adornment-password">
                                    Felhasználó ID
                                </InputLabel>
                                <Input
                                    value={values.user_id}
                                    type="text"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Tooltip title="Felhasználó adatai">
                                                <IconButton
                                                    onClick={() => {
                                                        navigate(`/users/edit/${values.user_id}`);
                                                    }}
                                                >
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Státusz"
                                value={values.status}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Belépés ideje"
                                required
                                type={values.entryTime ? 'datetime-local' : 'text'}
                                value={values.entryTime || '-'}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Kilépés ideje"
                                required
                                type={values.exitTime ? 'datetime-local' : 'text'}
                                value={values.exitTime || '-'}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Összeg (Ft)"
                                required
                                type="number"
                                value={values.amount}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Belépés utáni szabad idő"
                                required
                                value={values.freeTimeAfterEntry}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Fizetés utáni szabad idő"
                                required
                                value={values.freeTimeAfterPayment}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ pb: 4 }}>
                            <TextField
                                fullWidth
                                label="Fizetve"
                                required
                                value={values.paid === 1 ? 'Igen' : 'Nem'}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTable
                                rows={paymentRows}
                                limit={limit}
                                page={page}
                                setParams={setParams}
                                loading={paymentsLoading}
                                total={payments?.total}
                                columns={PAYMENTS_OF_TICKET_COLUMNS}
                                navigationLink="/payments/details/"
                                detailsIcon={<VisibilityIcon />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {hasHistory && (
                                <CommonButton
                                    onClick={() => {
                                        handleNavigateBack(navigate, location, '/parkings');
                                    }}
                                    label="Vissza"
                                    buttonType="text"
                                />
                            )}
                        </Grid>
                    </PageWithTitle>
                </Form>
            )}
        </Formik>
    );
}

export default ParkingDetailsPage;
