const handleNavigation = (location, navigate) => {
    const prevPaths = [];
    if (location.state?.history) {
        prevPaths.push(...location.state.history);
    }
    prevPaths.push(location.pathname);

    navigate({
        state: {
            history: prevPaths,
        },
    });
};

export default handleNavigation;
